





















































import { Component, Vue } from "vue-property-decorator";
import { BbsPostCommentDto } from "@/api/appService";
import api from "@/api";
import BbsPostComment from "@/views/bbs/bbsPostComment/list.vue";

@Component({
  name: "BbsPostCommentDetail",
  components: {},
  filters: {},
})
export default class BbsPostCommentDetail extends Vue {
  editId?: number;
  detail: BbsPostCommentDto = {};
  loading = true;
  bbsPostCommentsList: BbsPostCommentDto[] = [];
  created() {
    if (this.$route.params.id) {
      this.editId = Number(this.$route.params.id);
      this.fetchDetail();
      this.fetchBbsPostCommentsList();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.bbsPostComment.get({ id: this.editId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };
      console.log(res);
    });
    console.log(this.detail);
  }

  async fetchBbsPostCommentsList() {
    await api.bbsPostComment
      .getAllList({ parentId: this.editId })
      .then((res) => {
        this.bbsPostCommentsList = [...res!];
        console.log(this.bbsPostCommentsList);
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
